
import { defineComponent, onMounted, ref, watch } from "vue";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";

import { TableColumn } from "@/configs/orders";
import { columnWidths, columnConfigs } from "./columns";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";
import { Pagination } from "@/configs/common";

import PaginationCom from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "OrderModal",
  components: {
    PaginationCom,
  },
  props: {
    payCycle: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const tableRef = ref<HTMLElement>();
    const hot = ref();
    const columns = ref<TableColumn[]>(columnConfigs);
    const loading = ref<boolean>(false);
    const pagination = ref<Pagination>({
      page: 1,
      pageSize: 100,
    });

    const fetchCodPrepaidDetail = async (payCycle: string) => {
      loading.value = true;
      hot.value.loadData([]);
      const payload = {
        payCycle: payCycle,
        page: pagination.value.page || 1,
        pageSize: pagination.value.pageSize || 100,
        statusNotIn: "PENDING,CANCEL_ORDER",
      };
      try {
        loading.value = true;
        const res = await orderService.getCodPrepaidsDetail(payload);
        hot.value.loadData(res?.result?.cods);
        pagination.value.total = res?.result?.total;
      } catch (e) {
        showError(e);
      } finally {
        loading.value = false;
      }
    };

    const initHandson = () => {
      if (tableRef.value) {
        hot.value = new Handsontable(tableRef.value, {
          rowHeaders: true,
          colHeaders: columns.value.map((item) => item.header || ""),
          columns: columns.value,
          colWidths: columnWidths,
          rowHeights: 20,
          stretchH: "all",
          className: "htLeft handsongray",
          fixedColumnsLeft: 3,
          licenseKey: "non-commercial-and-evaluation",
          width: "100%",
          height: "100%",
        });
      }
    };

    const handlePaginationChange = (newVal: Pagination) => {
      pagination.value = { ...pagination.value, ...newVal };
      fetchCodPrepaidDetail(props.payCycle);
    };

    watch(
      () => props.payCycle,
      (newValue: string) => {
        fetchCodPrepaidDetail(newValue);
      }
    );

    onMounted(() => {
      initHandson();
    });
    return { tableRef, pagination, handlePaginationChange };
  },
});
