export const columnWidths = [80, 120, 300, 120, 80, 80, 80, 80];

const addressRenderer = (
  _instance: unknown,
  td: { className: string; innerHTML: string },
  _row: number,
  _col: number,
  _prop: string,
  value: string
): void => {
  td.className = "truncate htLeft";
  // eslint-disable-next-line no-control-regex
  td.innerHTML = value?.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ",");
};

export const columnConfigs = [
  {
    header: "Mã vận đơn",
    data: "billCode",
    readOnly: true,
  },
  {
    header: "Người nhận",
    data: "order.receiverName",
    readOnly: true,
  },
  {
    header: "Địa chỉ",
    data: "order.receiverAddress",
    readOnly: true,
    renderer: addressRenderer,
  },
  {
    header: "Ngày ký nhận",
    data: "order.signAt",
    readOnly: true,
  },
  {
    header: "Tiền Cod",
    data: "cod",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Vận phí",
    data: "freight",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  },
  {
    header: "Tiền thực nhận",
    data: "moneyBack",
    type: "numeric",
    readOnly: true,
    numericFormat: {
      pattern: "0,0",
      culture: "en-US", // this is the default culture, set up for USD
    },
  }
];
