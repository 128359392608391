
import { defineComponent } from "vue";
import { useManager } from "./useManager";
import CodPrepaidDetailModal from "@/components/cod-prepaid-detail-modal/Main.vue";

export default defineComponent({
  components: {
    CodPrepaidDetailModal,
  },
  setup() {
    const { tableRef, loading, dateRange, searchPrePaid, selectedCycle } = useManager();

    return { tableRef, loading, dateRange, searchPrePaid, selectedCycle };
  },
});
