<template>
  <div class="col-span-12">
    <!-- BEGIN: Change Password -->
    <div class="intro-y box">
      <div class="flex items-center p-3 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base ml-4 mr-12">Lịch sử phiên đối soát</h2>
        <Litepicker
          v-model="dateRange"
          :options="{
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="input border w-56 block mr-3"
        />
        <button type="button" class="button bg-theme-1 flex mr-auto justify-center text-white" @click="searchPrePaid">
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <SearchIcon class="w-4 h-4 mr-1" v-else /> Tra Cứu
        </button>
      </div>
      <div class="p-5">
        <div class="overflow-x-auto scrollbar-hidden">
          <div id="tabulator" ref="tableRef" class="table-report table-report--tabulator" style="height: 40vh"></div>
        </div>
      </div>
      <CodPrepaidDetailModal :pay-cycle="selectedCycle" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useManager } from "./useManager";
import CodPrepaidDetailModal from "@/components/cod-prepaid-detail-modal/Main.vue";

export default defineComponent({
  components: {
    CodPrepaidDetailModal,
  },
  setup() {
    const { tableRef, loading, dateRange, searchPrePaid, selectedCycle } = useManager();

    return { tableRef, loading, dateRange, searchPrePaid, selectedCycle };
  },
});
</script>
