import { helper } from "@/utils/helper";

export const columnConfigs = [
  {
    title: "Kỳ thanh toán",
    minWidth: 180,
    responsive: 0,
    field: "payCycle",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      return `<a href="javascript:" style="color:blue">${value}</a>`;
    },
  },
  {
    title: "Ngày thanh toán",
    minWidth: 180,
    responsive: 0,
    field: "payCycle",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      const dateString = value.split("_")[1];
      if (dateString?.length === 8)
        return dateString.substr(6, 2) + "-" + dateString.substr(4, 2) + "-" + dateString.substr(0, 4);
      return "";
    },
  },
  {
    title: "Tiền COD",
    minWidth: 180,
    responsive: 0,
    field: "cod",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      return helper.formatCurrency(value);
    },
  },
  {
    title: "Tiền thực nhận",
    minWidth: 180,
    responsive: 0,
    field: "moneyBack",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      return helper.formatCurrency(value);
    },
  },
  {
    title: "Tổng vận đơn",
    minWidth: 180,
    responsive: 0,
    field: "totalnumber",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
  },
  {
    title: "Xuất file Excel",
    minWidth: 180,
    responsive: 0,
    field: "payCycle",
    vertAlign: "middle",
    hozAlign: "center",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      return `<a style="color:blue" href="https://api.S-vip.vn/api/codPaids/export?pay_cycle=${value}" target="_blank">Xuất</a>`;
    },
  },
];
