import { onMounted, ref } from "vue";

import Tabulator from "tabulator-tables";

import { columnConfigs } from "./columns";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/configs/constants";
import { helper as $h } from "@/utils/helper";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";

export const useManager: any = () => {
  const tableRef = ref<HTMLElement>();
  const tabulator = ref();
  const loading = ref(false);
  const today = dayjs().format(DATE_FORMAT);
  const lastMonth = dayjs().subtract(1, "month").format(DATE_FORMAT);
  const dateRange = ref<string>(`${lastMonth} - ${today}`);
  const selectedCycle = ref<string>("");

  const searchPrePaid = async () => {
    const [startDate, endDate] = $h.dateRangeToDate(dateRange.value);
    const params = {
      page: 1,
      pageSize: 500,
      startDate: startDate,
      endDate: endDate,
    };

    try {
      loading.value = true;
      const res = await orderService.getCodPrepaids(params);
      tabulator.value.setData(res?.result?.cods);
    } catch (e) {
      showError(e)
    }
    finally {
      loading.value = false;
    }
  };

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
    });
  };

  const initTabulator: any = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      pagination: "local",
      paginationSize: 6,
      paginationSizeSelector: true,
      tooltipGenerationMode: "hover",
      placeholder: "Không có dữ liệu",
      layout: "fitColumns",
      columns: columnConfigs,
      cellClick: function (e: any, cell: any) {
        const header = cell.getColumn().getDefinition();
        if (header?.title === "Kỳ thanh toán") {
          selectedCycle.value = cell.getValue()
          cash("#cod-prepaid-detail-modal").modal("show");
        }
      },
    });
  };

  onMounted(() => {
    initTabulator();
    reInitOnResizeWindow();
    searchPrePaid();
  });

  return {
    tableRef,
    loading,
    dateRange,
    searchPrePaid,
    selectedCycle
  };
};
